import React from "react";
import { useOverrides, Override } from "@quarkly/components";
import { Text, Link, Box, Section } from "@quarkly/widgets";
const defaultProps = {
	"padding": "54px 0 30px 0",
	"quarkly-title": "Footer",
	"lg-padding": "30px 0 20px 0",
	"border-width": ".1px",
	"border-style": "solid",
	"border-color": "rgba(0, 0, 0, 0.76)",
	"background": "rgba(0, 0, 0, 0) linear-gradient(120deg,white 0%,--color-lightD2 100%) 0% 0% /auto repeat scroll padding-box"
};
const overrides = {
	"box": {
		"kind": "Box",
		"props": {
			"empty-min-height": "64px",
			"empty-border-width": "1px",
			"empty-border-style": "solid",
			"width": "33%",
			"md-width": "50%",
			"sm-margin": "0px 0px 16px 0px",
			"empty-min-width": "64px",
			"empty-border-color": "LightGray",
			"padding": "16px 16px 16px 16px",
			"md-align-items": "flex-start",
			"md-display": "flex",
			"md-justify-content": "center",
			"sm-padding": "16px 16px 16px 0px",
			"display": "flex",
			"flex-direction": "column"
		}
	},
	"text": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 0px 0px",
			"font": "--headline4",
			"children": "KnowledgeBridge"
		}
	},
	"link": {
		"kind": "Link",
		"props": {
			"margin": "auto 0px 16px 0px",
			"lg-font": "normal 400 16px/1.5 \"Manrope\", sans-serif",
			"href": "/politika-konfidencijnosti",
			"text-decoration-line": "initial",
			"color": "rgba(0, 0, 0, 0.78)",
			"font": "--lead",
			"display": "inline-block",
			"children": "Політика Конфіденційності"
		}
	},
	"box1": {
		"kind": "Box",
		"props": {
			"width": "33%",
			"flex-direction": "column",
			"md-width": "50%",
			"sm-padding": "16px 16px 16px 0px",
			"empty-border-style": "solid",
			"empty-min-height": "64px",
			"empty-border-width": "1px",
			"empty-border-color": "LightGray",
			"padding": "16px 16px 16px 16px",
			"display": "flex",
			"empty-min-width": "64px"
		}
	},
	"text1": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 32px 0px",
			"font": "--headline4",
			"color": "--dark",
			"lg-font": "600 24px/1.3 \"Manrope\", sans-serif",
			"children": "Навігація"
		}
	},
	"link1": {
		"kind": "Link",
		"props": {
			"margin": "0px 0px 16px 0px",
			"lg-font": "normal 400 16px/1.5 \"Manrope\", sans-serif",
			"href": "/index",
			"text-decoration-line": "initial",
			"color": "--dark",
			"font": "--lead",
			"children": "Домашня сторінка"
		}
	},
	"link2": {
		"kind": "Link",
		"props": {
			"lg-font": "normal 400 16px/1.5 \"Manrope\", sans-serif",
			"text-decoration-line": "initial",
			"color": "--dark",
			"font": "--lead",
			"margin": "0px 0px 16px 0px",
			"href": "/services",
			"children": "Програма курсу"
		}
	},
	"link3": {
		"kind": "Link",
		"props": {
			"color": "--dark",
			"font": "--lead",
			"margin": "0px 0px 16px 0px",
			"lg-font": "normal 400 16px/1.5 \"Manrope\", sans-serif",
			"href": "/kontakti",
			"text-decoration-line": "initial",
			"children": "Контакти"
		}
	},
	"box2": {
		"kind": "Box",
		"props": {
			"empty-min-height": "64px",
			"empty-border-width": "1px",
			"empty-border-color": "LightGray",
			"padding": "16px 16px 16px 16px",
			"display": "flex",
			"flex-direction": "column",
			"sm-padding": "16px 16px 16px 0px",
			"empty-min-width": "64px",
			"empty-border-style": "solid",
			"width": "33%",
			"md-width": "50%"
		}
	},
	"text2": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 32px 0px",
			"font": "--headline4",
			"color": "--dark",
			"lg-font": "600 24px/1.3 \"Manrope\", sans-serif",
			"children": "Контакти"
		}
	},
	"link4": {
		"kind": "Link",
		"props": {
			"text-decoration-line": "initial",
			"color": "--dark",
			"font": "--lead",
			"margin": "0px 0px 16px 0px",
			"lg-font": "normal 400 16px/1.5 \"Manrope\", sans-serif",
			"href": "0970936624",
			"children": "0970936624"
		}
	},
	"link5": {
		"kind": "Link",
		"props": {
			"margin": "0px 0px 16px 0px",
			"lg-font": "normal 400 16px/1.5 \"Manrope\", sans-serif",
			"text-decoration-line": "initial",
			"color": "--dark",
			"font": "--lead",
			"href": "knowledgebridge24.live",
			"children": "info@knowledgebridge24.live.com"
		}
	},
	"link6": {
		"kind": "Link",
		"props": {
			"lg-font": "normal 400 16px/1.5 \"Manrope\", sans-serif",
			"href": "#",
			"text-decoration-line": "initial",
			"color": "--dark",
			"font": "--lead",
			"margin": "0px 0px 16px 0px",
			"children": "бульвар Тараса Шевченка, 9, Тернопіль, Тернопільська область, 46002"
		}
	}
};

const Footer12 = props => {
	const {
		override,
		children,
		rest
	} = useOverrides(props, overrides, defaultProps);
	return <Section {...rest}>
		<Override slot="SectionContent" flex-direction="row" md-flex-wrap="wrap" />
		<Box {...override("box")}>
			<Text {...override("text")} />
			<Link {...override("link")} />
		</Box>
		<Box {...override("box1")}>
			<Text {...override("text1")} />
			<Link {...override("link1")} />
			<Link {...override("link2")} />
			<Link {...override("link3")} />
		</Box>
		<Box {...override("box2")}>
			<Text {...override("text2")} />
			<Link {...override("link4")} />
			<Link {...override("link5")} />
			<Link {...override("link6")} />
		</Box>
		{children}
	</Section>;
};

Object.assign(Footer12, { ...Section,
	defaultProps,
	overrides
});
export default Footer12;